// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-bdf-konteineriai-en-jsx": () => import("./../../../src/pages/bdf-konteineriai.en.jsx" /* webpackChunkName: "component---src-pages-bdf-konteineriai-en-jsx" */),
  "component---src-pages-bdf-konteineriai-jsx": () => import("./../../../src/pages/bdf-konteineriai.jsx" /* webpackChunkName: "component---src-pages-bdf-konteineriai-jsx" */),
  "component---src-pages-index-en-jsx": () => import("./../../../src/pages/index.en.jsx" /* webpackChunkName: "component---src-pages-index-en-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-itempiamos-priekabos-en-jsx": () => import("./../../../src/pages/itempiamos-priekabos.en.jsx" /* webpackChunkName: "component---src-pages-itempiamos-priekabos-en-jsx" */),
  "component---src-pages-itempiamos-priekabos-jsx": () => import("./../../../src/pages/itempiamos-priekabos.jsx" /* webpackChunkName: "component---src-pages-itempiamos-priekabos-jsx" */),
  "component---src-pages-konteineriu-transportavimas-en-jsx": () => import("./../../../src/pages/konteineriu-transportavimas.en.jsx" /* webpackChunkName: "component---src-pages-konteineriu-transportavimas-en-jsx" */),
  "component---src-pages-konteineriu-transportavimas-jsx": () => import("./../../../src/pages/konteineriu-transportavimas.jsx" /* webpackChunkName: "component---src-pages-konteineriu-transportavimas-jsx" */),
  "component---src-pages-pavojingu-kroviniu-transportavimas-jsx": () => import("./../../../src/pages/pavojingu-kroviniu-transportavimas.jsx" /* webpackChunkName: "component---src-pages-pavojingu-kroviniu-transportavimas-jsx" */),
  "component---src-pages-pavojingu-kroviniu-trasnportavimas-en-jsx": () => import("./../../../src/pages/pavojingu-kroviniu-trasnportavimas.en.jsx" /* webpackChunkName: "component---src-pages-pavojingu-kroviniu-trasnportavimas-en-jsx" */),
  "component---src-pages-refrizeratoriu-deziu-priekabos-en-jsx": () => import("./../../../src/pages/refrizeratoriu-deziu-priekabos.en.jsx" /* webpackChunkName: "component---src-pages-refrizeratoriu-deziu-priekabos-en-jsx" */),
  "component---src-pages-refrizeratoriu-deziu-priekabos-jsx": () => import("./../../../src/pages/refrizeratoriu-deziu-priekabos.jsx" /* webpackChunkName: "component---src-pages-refrizeratoriu-deziu-priekabos-jsx" */)
}

